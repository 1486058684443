<script setup lang="ts">
import { Icon } from 'webcc-ui-components'

enum Icons {
  Up = 'up',
  Down = 'down',
  Even = 'even',
}

const props = withDefaults(
  defineProps<{
    difference: number
  }>(),
  { difference: 0 },
)

const { t } = useI18n()

const bottomInfo = computed(() => {
  let text = '0'
  let icon = Icons.Even

  if (props.difference > 0) {
    text = '+'.concat(props.difference.toString())
    icon = Icons.Up
  } else if (props.difference < 0) {
    text = ''.concat(props.difference.toString())
    icon = Icons.Down
  } else if (props.difference === 99.9) {
    text = t('components.common.noData')
    icon = Icons.Even
  }

  return { text, icon }
})

const iconClasses = computed(
  () =>
    ({
      [Icons.Up]: 'bg-suc text-txt-white',
      [Icons.Down]: 'bg-err text-txt-white',
      [Icons.Even]: 'bg-bgr text-txt-400',
    })[bottomInfo.value.icon] || 'bg-bgr-200 text-txt-400',
)
</script>

<template>
  <div class="flex items-center">
    <span
      class="mr-1 flex h-5 w-5 items-center justify-center rounded-full"
      :class="iconClasses"
    >
      <Icon class="h-2.5 w-2.5 shrink-0" :graphic="bottomInfo.icon" />
    </span>

    <span class="text-sm font-normal text-txt-400">
      {{ bottomInfo.text }}%
    </span>
  </div>
</template>
